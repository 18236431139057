export const Service14 = require('./service14.jpg');
export const Service15 = require('./service15.jpg');
export const Service16 = require('./service16.jpg');
export const Service17 = require('./service17.jpg');
export const Service18 = require('./service18.jpg');
export const Service19 = require('./service19.jpg');
export const BrownConcreteRoof = require ('./brown-concrete-roofs-1920x600.jpg')
export const Block1 = require('./featuredblock1.jpg');
export const Block2 = require('./featuredblock2.jpg');
export const Block3 = require('./featuredblock3.jpg');
export const Hero = require('./hero.jpg');
export const Logo = require('./logo-vert-square-x512.png');
export const PatternBg = require('./pattern-bg.jpg');
export const PatternRoofRed1 = require('./pattern1.png');
export const PatternRoofBlue = require('./pattern2.png');
export const PatternRoofBanner = require('./roofbanner.jpg');
export const Inspections = require('./service-inspections.jpg');
export const Restorations = require('./service-restorations.jpg');
export const Repair = require('./service-roofrepair.jpg');
export const Replace = require('./service-roofreplace.jpg');
export const Skylights = require('./service-skylights.jpg');
export const Solar = require('./service-solar.jpg');
export const Systemslogo = require('./system-logo.jpg');
export const SystemsImage = require('./systems.jpg');
export const CustomersLove = require('./customers-love.jpg');
export const RoofOnly = require('./logo-roof-only.png');
export const Reviewer = require('./reviewer.jpeg');
export const Reviewer2 = require('./reviewer2-500x500.jpg');
export const Climbing = require('./climbing.jpg');
export const ProgressBg = require('./progressBg.jpg');
export const Toolbelt = require('./toolbelt.jpg');
export const LogoWshadowDark = require('./logo-Wshadow-dark.png');
export const LogoWshadowLight = require('./logo-Wshadow-light.png');
export const ComingSoon1 = require('./architectural-comingsoon.jpg');
export const ComingSoon1Md = require('./architectural-comingsoon-767x431.jpg');
export const ComingSoon2 = require('./roofs-comingsoon2.jpg');
export const LogoWshadowDarkVertLg = require('./logo-vertical-dark-2000x1643.png');
export const LogoWshadowLightVertLg = require('./logo-vertical-light-2000x1643.png');
export const LogoWshadowDarkVertMd = require('./logo-vertical-dark-500x410.png');
export const LogoWshadowLightVertMd = require('./logo-vertical-light-500x410.png');
export const LogoWshadowDarkVertSm = require('./logo-vertical-dark-300x246.png');
export const LogoWshadowLightVertSm = require('./logo-vertical-light-300x246.png');
export const BadgeHAbest2019 = require('./badges-best2019.png');
export const BadgeHAelite = require('./badges-elite.png');
export const BadgeHAtoprated = require('./badges-toprated.png');
export const CustomersLoveGuy = require('./customersloveus.png');
export const MenuIcon = require('./menuIcon2-whitebg.png')
export const BirdsEye = require('./new/birdseye.jpeg')
export const WoodFrame = require('./new/woodframe_1920.jpg')
export const HouseBuilding = require('./new/housebuilding_1920.jpg')
export const TwoRoofersVert = require('./new/TwoRoofersVert_1920.jpg')
export const RooferOnSlant = require('./new/RooferOnSlant.jpg')
export const PinkPanther = require('./new/pinkpanther-tracenosmooth.png')
export const OwensPreferred = require('./new/owens-preferred2.png')
export const Avatar = require('./new/avatar_640.png');
export const PhoneNumber = require('./new/phone-sm.png');
export const PhonenumberHorz = require('./new/phone2Horz-Md.png')
export const FullLogoNoBgMd = require('./new/full-logo-noBG-x512.png')
/*************  City Pages  **************/
/**** Escondido */
export const Esco1 = require('./zenith-city-pics/esco/esco-1.jpeg')
export const Esco2 = require('./zenith-city-pics/esco/esco-2.jpeg')
export const Esco3 = require('./zenith-city-pics/esco/esco-3.png')
export const Esco4 = require('./zenith-city-pics/esco/esco-4.jpeg')
export const Esco5 = require('./zenith-city-pics/esco/esco-5.png')
export const Esco6 = require('./zenith-city-pics/esco/esco-6.png')
export const Esco7 = require('./zenith-city-pics/esco/esco-7.jpeg')
export const Esco8 = require('./zenith-city-pics/esco/esco-8.png')
export const Esco9 = require('./zenith-city-pics/esco/esco-9.png')
export const Esco10 = require('./zenith-city-pics/esco/esco-10.jpeg')
export const Esco11 = require('./zenith-city-pics/esco/esco-11.jpeg')
export const Esco12 = require('./zenith-city-pics/esco/esco-12.jpeg')
/********* Carlsbad */
export const Carlsbad1 = require('./zenith-city-pics/carlsbad/carlsbad-1.jpeg')
export const Carlsbad2 = require('./zenith-city-pics/carlsbad/carlsbad-2.jpeg')
export const Carlsbad3 = require('./zenith-city-pics/carlsbad/carlsbad-3.png')
export const Carlsbad4 = require('./zenith-city-pics/carlsbad/carlsbad-4.png')
export const Carlsbad5 = require('./zenith-city-pics/carlsbad/carlsbad-5.png')
export const Carlsbad6 = require('./zenith-city-pics/carlsbad/carlsbad-6.png')
export const Carlsbad7 = require('./zenith-city-pics/carlsbad/carlsbad-7.png')
export const Carlsbad8 = require('./zenith-city-pics/carlsbad/carlsbad-8.jpeg')
export const Carlsbad9 = require('./zenith-city-pics/carlsbad/carlsbad-9.png')
export const Carlsbad10 = require('./zenith-city-pics/carlsbad/carlsbad-10.png')
export const Carlsbad11 = require('./zenith-city-pics/carlsbad/carlsbad-11.jpeg')
export const Carlsbad12 = require('./zenith-city-pics/carlsbad/carlsbad-12.png')
export const Carlsbad13 = require('./zenith-city-pics/carlsbad/carlsbad-13.png')
/********** Vista */
export const Vista1 = require('./zenith-city-pics/vista/vista-1.png')
export const Vista2 = require('./zenith-city-pics/vista/vista-2.jpeg')
export const Vista3 = require('./zenith-city-pics/vista/vista-3.png')
export const Vista4 = require('./zenith-city-pics/vista/vista-4.jpeg')
export const Vista5 = require('./zenith-city-pics/vista/vista-5.jpeg')
export const Vista6 = require('./zenith-city-pics/vista/vista-6.png')
export const Vista7 = require('./zenith-city-pics/vista/vista-7.jpeg')
export const Vista8 = require('./zenith-city-pics/vista/vista-8.jpeg')
export const Vista9 = require('./zenith-city-pics/vista/vista-9.png')
export const Vista10 = require('./zenith-city-pics/vista/vista-10.png')
export const Vista11 = require('./zenith-city-pics/vista/vista-11.png')
/************ San Marcos */

/************* Oceanside */

/************** Rancho Bernardo */

/*************** Poway */

/*****************Services */
/********* BUR */
export const BUR1 = require('./services/Built-Up Roofing/21-04-22_13-35-31_5404-1500x1125.jpg')
export const BUR2 = require('./services/Built-Up Roofing/21-04-02_13-11-15_4929.png')
export const BUR3 = require('./services/Built-Up Roofing/21-04-02_13-11-16_4930.png')
export const BUR4 = require('./services/Built-Up Roofing/21-04-02_13-12-18_4941.png')
export const BUR5 = require('./services/Built-Up Roofing/21-04-02_13-12-28_4943.png')
export const BUR6 = require('./services/Built-Up Roofing/21-04-02_13-12-31_4944.png')
export const BUR7 = require('./services/Built-Up Roofing/21-04-22_13-35-33_5405.jpg')
export const BUR8 = require('./services/Built-Up Roofing/21-04-22_13-35-33_5406.jpg')
export const BUR9 = require('./services/Built-Up Roofing/21-09-28_11-49-47_ACB1.jpg')
export const BUR10 = require('./services/Built-Up Roofing/21-09-28_11-49-48_6964.jpg')
export const BUR11 = require('./services/Built-Up Roofing/21-09-28_11-49-51_3FCA.jpg')
export const BUR12 = require('./services/Built-Up Roofing/21-09-28_11-49-53_CA13.jpg')
export const BUR13 = require('./services/Built-Up Roofing/21-09-28_11-49-54_3BA8.jpg')


/******** EPDM */
export const EPDM1 = require('./services/EPDM/epdm-1.jpg')
export const EPDM2 = require('./services/EPDM/epdm-2.jpg')
/*export const EPDM3 = require('./services/EPDM/')
export const EPDM4 = require('./services/EPDM')
export const EPDM5 = require('./services/EPDM')
export const EPDM6 = require('./services/EPDM')
export const EPDM7 = require('./services/EPDM')
export const EPDM8 = require('./services/EPDM')
export const EPDM9 = require('./services/EPDM')
export const EPDM10 = require('./services/EPDM')
export const EPDM11 = require('./services/EPDM')
export const EPDM12 = require('./services/EPDM')
*/




/*********TPO */
export const TPO1 = require('./services/Single Ply Roof TPO/IMG_0380-1500x1125.jpg')


/******** Tile */
export const Tile1 = require('./services/Tile Roof Replacement/C55C03E5-0F18-41F4-BB88-C152BA99BC4B.jpg')


/******** Restorations */
export const Restorations1 = require('./services/Tile Roof Lift and Lay/21-09-25 12-26-20 DE85-square.jpg')

/******** Replacements */
export const Replacements1 = require('./services/roof-replacements/21-03-25_18-50-59_4754.jpg')
export const Replacements2 = require('./services/roof-replacements/21-03-25_18-50-59_4755.jpg')
export const Replacements3 = require('./services/roof-replacements/21-03-29_13-51-39_4821.jpg')
export const Replacements4 = require('./services/roof-replacements/21-03-29_13-51-39_4823.jpg')


/******** Repairs */
//export const Repairs1 = require('./services/')


/********* Maintenance */
//export const Maintenance1 = require('./services/')


/******** PVC */
export const PVC1 = require('./services/Single Ply PVC/IMG_0384.jpg')
export const PVC2 = require('./services/Single Ply PVC/IMG_0379.jpg')
export const PVC3 = require('./services/Single Ply PVC/IMG_0380.jpg')
export const PVC4 = require('./services/Single Ply PVC/IMG_0383.jpg')

/******** Owens Corning */
export const OwensCorning1 = require('./services/Owens Corning/RemoteMediaFile_6553661_0_2021_09_22_13_58_30-1500x843.jpg')


/******** Solar */
export const Solar1 = require('./services/solar-prep/IMG_6794-1500x1125.jpg')
export const Solar2 = require('./services/solar-prep/2021-03-10-12-54-01-328-fs8-fs8.png')
export const Solar3 = require('./services/solar-prep/2021-03-10-12-54-25-778-fs8-fs8.png')
export const Solar4 = require('./services/solar-prep/2021-03-26-15-25-15-356-fs8.png')
export const Solar5 = require('./services/solar-prep/2021-03-26-15-25-31-961-fs8-fs8.png')
export const Solar6 = require('./services/solar-prep/2021-03-27-09-30-10-389-fs8.png')
export const Solar7 = require('./services/solar-prep/2021-03-27-09-30-18-205-fs8-fs8.png')
export const Solar8 = require('./services/solar-prep/2021-03-27-09-30-25-048-fs8-fs8.png')
export const Solar9 = require('./services/solar-prep/2021-07-02-13-02-54-579-fs8-fs8.png')
export const Solar10 = require('./services/solar-prep/IMG_4689-fs8-fs8.png')



/******** Skylight */
export const Skylight1 = require('./services/Skylight Repair or Replacement/2021-08-20-18-11-54-470-fs8.png')
export const Skylight2 = require('./services/Skylight Repair or Replacement/IMG_7389-1500x1125.jpg')
export const Skylight3 = require('./services/skylights/2021-06-17-14-52-55-859-fs8.png')
export const Skylight4 = require('./services/skylights/2021-08-20-18-11-54-470-fs8.png')
export const Skylight5 = require('./services/skylights/IMG_0256.jpg')
export const Skylight6 = require('./services/skylights/IMG_5268.jpg')
export const Skylight7 = require('./services/skylights/IMG_6710.jpg')



/******** Shingle */
export const Shingle1 = require('./services/Shingle Roof Replacement/21-07-15_17-50-12_6652-1500x1125.jpg')
export const Shingle2 = require('./services/shingles/20-10-13_21-37-24_1393.jpg')
export const Shingle3 = require('./services/shingles/21-02-01_17-27-11_3453.jpg')
export const Shingle4 = require('./services/shingles/21-03-02_11-26-11_4127.png')
export const Shingle5 = require('./services/shingles/21-03-02_11-26-18_4128.png')
export const Shingle6 = require('./services/shingles/21-03-27_09-26-35_4775.png')
export const Shingle7 = require('./services/shingles/21-07-15_17-50-00_7490.jpg')
export const Shingle8 = require('./services/shingles/21-07-15_17-50-12_6604.jpg')
export const Shingle9 = require('./services/shingles/21-08-26_12-17-22_7533.png')
export const Shingle10 = require('./services/shingles/21-08-26_12-20-47_7540.png')




